/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "eu-central-1",
    "aws_cloud_logic_custom": [
        {
            "name": "AdminQueries",
            "endpoint": "https://oo9545b7bh.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        },
        {
            "name": "expenslyREST",
            "endpoint": "https://cqtpwosabc.execute-api.eu-central-1.amazonaws.com/dev",
            "region": "eu-central-1"
        }
    ],
    "aws_appsync_graphqlEndpoint": "https://5gh7gg4trrbjfdrpb2yvcqwvg4.appsync-api.eu-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "eu-central-1",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_cognito_identity_pool_id": "eu-central-1:ed74927e-f93f-4024-83d8-fd7a97d6f986",
    "aws_cognito_region": "eu-central-1",
    "aws_user_pools_id": "eu-central-1_FvmYJtNoS",
    "aws_user_pools_web_client_id": "3adkfcv7fp8g8qps70h156tc1k",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OPTIONAL",
    "aws_cognito_mfa_types": [
        "TOTP"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": "12",
        "passwordPolicyCharacters": [
            "REQUIRES_LOWERCASE",
            "REQUIRES_UPPERCASE",
            "REQUIRES_NUMBERS",
            "REQUIRES_SYMBOLS"
        ]
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_user_files_s3_bucket": "expensly150556-dev",
    "aws_user_files_s3_bucket_region": "eu-central-1"
};


export default awsmobile;
